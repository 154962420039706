import { ContactCard as ContactCardUI } from "@business-finland/wif-ui-lib/dist/ContactCard";

interface IContactProps {
  contacts: Wif.Mgnl.Node;
}

export function ContactCard({ contacts }: IContactProps): JSX.Element {
  if (contacts["@nodes"].length) {
    const contactLists = contacts["@nodes"].map((nodeId) => {
      const contact = contacts[nodeId];
      return {
        title: contact.title,
        description: contact.description,
        phone: contact.phone,
        email: contact.email,
        link: contact.link,
      };
    });
    return (
      <div>
        <ContactCardUI contacts={contactLists} />
      </div>
    );
  }
  return <></>;
}
